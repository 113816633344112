import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import ConditionalCTA from 'src/components/ConditionalCTA'
import LoadingAnimation from 'src/components/LoadingAnimation'
import SEO from 'src/components/seo'
import {
  Section,
  Header,
  Details,
  BlogPostsContainer,
  BlogImage,
  AuthorContainer,
  Flexbox,
  BlogDetails,
  Name,
  PublishDetail,
  BuyNowLink,
  BuyNowAnchor,
  AuthorImage,
} from '../../utils/styles/basicSingleColumnBlog.js'
import {
  Container,
  Text,
  BlogImageContainer,
  ButtonContainer,
} from '../../utils/styles/singleColumnBlog.js'

class BasicSingleColumnBlogPage extends React.Component {
  render() {
    const { pageContext } = this.props
    if (!pageContext?.fields) return <LoadingAnimation />
    const {
      slug,
      fields: {
        seo,
        hero,
        sections,
        footer: { cta },
        indexing,
      },
    } = pageContext
    return (
      <Section>
        <SEO title={seo.title} description={seo.meta_description} path={`/${slug}`} />
        {!indexing && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <Container>
          <Text>
            <Header>{hero.headline}</Header>
            <p>{hero.sub_headline}</p>
            <AuthorContainer>
              <AuthorImage src={hero.author_image} slt="Portrait of Author " />
              <BlogDetails>
                <Name>{hero.author_name} </Name>
                <Flexbox>
                  <PublishDetail>{hero.publish_date}</PublishDetail>
                  <span>&#8226;</span>
                  <PublishDetail>2 min read</PublishDetail>
                  <span>&#9733;</span>
                </Flexbox>
              </BlogDetails>
            </AuthorContainer>
          </Text>
          <BlogPostsContainer>
            {sections.map((section, i) => (
              <Details key={i}>
                <>
                  {section.image ? (
                    <>
                      <BlogImageContainer>
                        <BlogImage src={section.image} alt={section.image_title} />
                      </BlogImageContainer>
                      <Text left dangerouslySetInnerHTML={{ __html: section.text }} />
                    </>
                  ) : (
                    <Text margin="auto" left dangerouslySetInnerHTML={{ __html: section.text }} />
                  )}
                  {section.buy_now_button && (
                    <ButtonContainer>
                      <ConditionalCTA
                        url={hero.cta_button_link}
                        text={hero.cta_button_text}
                        anchorComponent={BuyNowAnchor}
                      />
                    </ButtonContainer>
                  )}
                </>
              </Details>
            ))}
            {cta && (
              <ButtonContainer>
                <ConditionalCTA
                  url={hero.cta_button_link}
                  text={cta}
                  anchorComponent={BuyNowAnchor}
                  linkComponent={BuyNowLink}
                />
              </ButtonContainer>
            )}
          </BlogPostsContainer>

          <br />
        </Container>
      </Section>
    )
  }
}

BasicSingleColumnBlogPage.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string.isRequired,
        meta_description: PropTypes.string.isRequired,
      }),
      hero: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        cta_button_link: PropTypes.string.isRequired,
        cta_button_text: PropTypes.string.isRequired,
        sub_headline: PropTypes.string,
        author_name: PropTypes.string,
        publish_date: PropTypes.string,
        author_image: PropTypes.string,
      }),
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
          image_title: PropTypes.string,
          text: PropTypes.string.isRequired,
          buy_now_button: PropTypes.string,
        })
      ),
      footer: PropTypes.shape({
        cta: PropTypes.string,
      }),
      indexing: PropTypes.string.isRequired,
    }),
  }),
}

export default BasicSingleColumnBlogPage
