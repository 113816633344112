import styled, { css } from 'styled-components'
import { breakpoints, BigHeader, BuyNowLinkCSS } from 'src/utils/styles/global-styles'
import { Link } from 'gatsby'
import { Content } from './singleColumnBlog'

export const Section = styled.div`
  background-color: white;
  padding-bottom: 150px;
  @media (max-width: ${breakpoints.m}px) {
    padding-bottom: 100px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 50px;
  }
`

export const Header = styled(BigHeader)`
  font-family: Helvetica;
  margin: 0px;
  padding-top: 100px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 38px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 2rem;
    padding-top: 50px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.5rem;
  }
`

export const BlogPostsContainer = styled.section`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li > strong {
    font-family: Helvetica;
  }
  b,
  strong {
    font-weight: bold;
  }
  em {
    font-family: Helvetica;
    font-style: normal;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: 0.13px;
    color: ${(props) => props.theme.grayDark};
    margin-bottom: 10px;
    @media (max-width: ${breakpoints.s}px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
`
export const Details = styled(Content)`
  font-family: Helvetica;
  align-items: center;
  p,
  span {
    font-family: 'filsonProBook';
  }
`

export const BlogImage = styled.img`
  width: 100%;
`

export const BuyNowLink = styled(Link)`
  ${BuyNowLinkCSS}
  background-color: rgb(207, 17, 44);
  &:hover {
    cursor: pointer;
    background-color: rgba(204, 0, 255, 0.9) !important;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgb(207, 17, 44, 0.9) !important;
  }
`
export const AuthorContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 30px 0px 10px;
`
export const Name = styled.p`
  font-family: Helvetica;
  font-weight: 400;
  margin: 0px;
  padding: 0px 5px;
`
export const PublishDetail = styled.p`
  font-family: Helvetica;
  color: rgba(110, 110, 110, 1);
  padding: 0px 5px;
  margin: 0px;
`
export const BlogDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
  }
`
export const Flexbox = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0px;
`
const linkStyle = css`
  font-family: 'FilsonPro';
  background-color: rgb(207, 17, 44);
  color: white;
  text-decoration: none;
  border-radius: 28px;
  border: none;
  font-size: 18px;
  padding: 15px 30px;
  line-height: 1.28;
  letter-spacing: 0.09px;
  &:hover {
    cursor: pointer;
    background-color: rgba(207, 17, 44, 0.9);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(207, 17, 44, 0.9);
    @media (max-width: ${breakpoints.m}px) {
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0.08px;
    }
  }
`
export const BuyNowAnchor = styled.a`
  ${linkStyle}
`
export const AuthorImage = styled.img`
  width: 80px;
  @media (max-width: ${breakpoints.s}px) {
    width: 50px;
  }
`
